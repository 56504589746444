body {
  overflow-x: hidden;
}

* {
  /* Prevent any object from being highlighted upon touch event*/
  -webkit-tap-highlight-color: transparent;
}

.animation {
  background-image: linear-gradient(90deg, #404041 50%, transparent 50%),
    linear-gradient(90deg, #404041 50%, transparent 50%),
    linear-gradient(0deg, #404041 50%, transparent 50%),
    linear-gradient(0deg, #404041 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 1.5px, 15px 1.5px, 1.5px 15px, 1.5px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  border-radius: 10px;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.dashedBorder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 20px;
}

.animation1 {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
  border-radius: 10px;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/url.jpg");
  background-size: cover;
}

@keyframes lineDraw {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

.blocktext {
  display: -webkit-box;
  max-width: 310px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  visibility: visible;
}

.highlightHomeIntro {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: max-content;
  position: relative;
  overflow: hidden;
}
.highlightHomeIntro::before {
  content: "";
  position: absolute;
  /* background: linear-gradient(180deg,rgba(255,255,255,0) 60%, #F7941ECC 50%);  */
  background-color: #f7941e;
  z-index: -1;
  height: 40%;
  top: 50%;
  animation: lineDraw 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.highlightHomeHistory {
  text-align: center;
  width: max-content;
  position: relative;
  overflow: hidden;
}
.highlightHomeHistory::before {
  content: "";
  position: absolute;
  /* background: linear-gradient(180deg,rgba(255,255,255,0) 60%, #F7941ECC 50%);  */
  background-color: #f7941e;
  z-index: -1;
  height: 40%;
  top: 50%;
  animation: lineDraw 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.hoverGradient {
  position: relative;
  z-index: 20;
}

.hoverGradient::before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  left: 0%;
  height: 100%;
  border-radius: 10px;
}

.hoverGradient:hover:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    #ffffff;
}

.highlight {
  position: relative;
  width: max-content;
}

.highlight::before {
  content: "";
  width: 100px;
  position: absolute;
  /* background: linear-gradient(180deg,rgba(255,255,255,0) 60%, #F7941ECC 50%);  */
  background-color: #f7941e;
  z-index: -1;
  height: 40%;
  top: 60%;
  animation: lineDraw 1.5s linear forwards;
}

.highlight2 {
  position: relative;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1024px) {
  .highlight2 {
    margin-left: 0;
    margin-right: 0;
  }
}

.highlight2::before {
  content: "";
  width: 100px;
  position: absolute;
  /* background: linear-gradient(180deg,rgba(255,255,255,0) 60%, #F7941ECC 50%);  */
  background-color: #f7941e;
  z-index: -1;
  height: 40%;
  top: 60%;
  animation: lineDraw 1.5s linear forwards;
}

.cardClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardClampCareerCard {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul > li::marker {
  font-weight: bold;
  margin-right: 4px;
  color: #3f2305;
}

.highlightBottomToTop {
  width: max-content;
  position: relative;
  overflow: hidden;
}

.highlightBottomToTop::before {
  content: "";
  position: absolute;
  background-color: rgba(247, 148, 30, 0.5);
  z-index: -1;
  /* margin-top: 3%;   */
  height: 150%;
  top: 55%;
  bottom: 0;
  width: 100%;
  animation: lineDrawToTop1 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.highlightBottomToTop:hover::before {
  content: "";
  position: absolute;
  background-color: rgba(247, 148, 30, 0.5);
  z-index: -1;
  height: 150%;
  top: 55%;
  bottom: 0;
  width: 100%;
  animation: lineDrawToTop 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.hoverGradient:hover .highlightBottomToTop::before {
  content: "";
  position: absolute;
  background-color: rgba(247, 148, 30, 0.5);
  z-index: -1;
  height: 150%;
  top: 55%;
  bottom: 0;
  width: 100%;
  animation: lineDrawToTop 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes lineDrawToTop {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes lineDrawToTop1 {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

.highlight1 {
  width: max-content;
  position: relative;
  overflow: hidden;
}

.highlight1::before {
  content: "";
  position: absolute;
  background-color: #f7941e;
  z-index: -1;
  height: 40%;
  top: 60%;
  animation: lineDraw 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.highlightGrey {
  position: relative;
  overflow: hidden;
  max-width: max-content;
  z-index: 20;

  /* background: linear-gradient(180deg,rgba(255,255,255,0) 60%, #BFBABA 50%); */
}

.highlightGrey::before {
  content: " ";
  position: absolute;
  z-index: -1;
  height: 40%;
  top: 60%;
  background-color: #f7941e !important;

  animation: lineDraw 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.year {
  /* writing-mode: vertical-lr; */
  /* transform: rotate(-90deg); */
  background: conic-gradient(
    from 143.13deg at 50.37% 49.63%,
    #f08300 -37.5deg,
    #d0d0d0 45deg,
    #f08300 322.5deg,
    #d0d0d0 405deg
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.year1 {
  background: #f08300;
  background: linear-gradient(
    to top right,
    #f08300 10%,
    #d0d0d0 30%,
    #d0d0d0 10%,
    #ff7f04 25%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.testr {
  border-radius: 60% 40% 59% 41% / 56% 49% 51% 44%;
}
.box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='black' stroke-width='2' stroke-dasharray='9%2c 14' stroke-dashoffset='56' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 50px;
}

.hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/url.jpg");
  background-size: cover;
}

.triangle1 {
  transition: 2s;
  transform: rotate(80deg) translatex(50px) translatey(-40px) scale(100%)
    skew(4deg, 20deg);
}

.triangle2 {
  transition: 2s;
  transform: translatey(-130px) scale(100%) rotate(180deg) skew(4deg, 20deg);
}
.triangle3 {
  transition: 2s;
  transform: rotate(40deg) scale(100%) translate(-10px, -50px) skew(4deg, 3deg);
}
.triangle4 {
  transition: 2s;
  transform: translate(-190px, 100px) scale(100%) rotate(50deg);
}
.container:hover .triangle1 {
  transition: 2s;
  transform: scale(100%) rotate(180deg) translate(-188px, 109px);
}

.container:hover .triangle2 {
  transition: 2.5s;
  transform: scale(100%) translate(60px);
}
.container:hover .triangle3 {
  transition: 2s;

  transform: scale(100%) skew(0deg, 0deg) rotate(180deg) translate(2px);
}
.container:hover .triangle4 {
  transition: 2s;
  transform: scale(100%) translate(-257px, 2px) rotate(180deg);
}

.nsepPoly1 {
  transform: translate(40%, 52%);
}
.nsepPoly2 {
  transform: rotate(180deg) translate(0, -20%);
}
.nsepPoly3 {
  transform: rotate(180deg) translate(60%, -125%);
}

.swiper-pagination-bullet-active {
  background-color: #6b7280 !important;
}

.scroll:hover {
  /* -moz-animation: scroll 3s linear 1; */
  /* -webkit-animation: scroll 7s linear 1;  */
  animation: scroll 1s linear 1;
  animation-fill-mode: forwards;
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -20%);
  }
}

/* html{
  color-scheme: dark;
} */

.poly1 {
  transform: translate(35%, 25%);
}

.poly3 {
  transform: translate(-58%, 106%);
  /* -120px,160px */
}

.button-with-gradient-border {
  background-image: radial-gradient(
      circle at 100% 100%,
      transparent 10px,
      #ffbe6f 31px,
      #ffbe6f 32px,
      transparent 32px
    ),
    linear-gradient(to right, #ffbe6f, #ff7800),
    radial-gradient(
      circle at 0% 100%,
      transparent 31px,
      #ff7800 31px,
      #ff7800 32px,
      transparent 32px
    ),
    linear-gradient(to bottom, #ff7800, #e66100),
    radial-gradient(
      circle at 0% 0%,
      transparent 31px,
      #e66100 31px,
      #e66100 32px,
      transparent 32px
    ),
    linear-gradient(to left, #e66100, #ffffff),
    radial-gradient(
      circle at 100% 0%,
      transparent 31px,
      #ffffff 31px,
      #ffffff 32px,
      transparent 32px
    ),
    linear-gradient(to top, #ffffff, #ffbe6f);
  background-size: 32px 32px, calc(100% - 64px) 1px, 32px 32px,
    1px calc(100% - 64px);
  background-position: top left, top center, top right, center right,
    bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
}

.gradientButton {
  border-radius: 100rem;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1px transparent;
  background-image: linear-gradient(
      rgba(208, 208, 208, 1),
      rgba(240, 131, 0, 1)
    ),
    linear-gradient(101deg, #d0d0d0, #ffbe6f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.gardientbutton1:hover {
  border: solid 1px transparent;
  background-image: linear-gradient(
      rgba(208, 208, 208, 1),
      rgba(240, 131, 0, 1)
    ),
    linear-gradient(101deg, #d0d0d0, #ffbe6f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg) scale(0.9);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }

  100% {
    transform: rotate(360deg) scale(0.9);
  }
}

@keyframes reverse-rotation {
  0% {
    transform: rotate(360deg scale(1));
  }

  50% {
    transform: rotate(180deg) scale(0.7);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

.half-a-border-on-top {
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, #f7941e 50%, transparent 50%) 100% 1;
}

.divWithoutHoverMobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3));
}

.divWithoutHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.mainHoverDiv:hover .secondaryHoverDiv {
  /* background: linear-gradient(0deg, rgba(173, 114, 0, 0.5), rgba(173, 114, 0, 0.5)); */
  top: 0%;
  animation-duration: 700ms;
}

.mainHoverDiv:hover {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) */
  /* background: linear-gradient(0deg, rgba(173, 114, 0, 0.5), rgba(173, 114, 0, 0.5)), */
  background: linear-gradient(
    0deg,
    rgba(173, 114, 0, 0.5),
    rgba(173, 114, 0, 0.5)
  );
}

.mainHoverDiv {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.7, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}

.svgGradient {
  fill: conic-gradient(
    from 143.13deg at 50.37% 49.63%,
    #f08300 -37.5deg,
    #d0d0d0 45deg,
    #f08300 322.5deg,
    #d0d0d0 405deg
  ) !important;
}

.discSpin {
  transform-origin: center;
  animation: spin 4s linear infinite;
  transition-duration: 300ms;
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  50% {
    rotate: 280deg;
  }
  75% {
    top: 200px;
  }
  100% {
    top: 0px;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-50%) translateY(100%);
  }
  to {
    transform: translateX(-50%) translateY(-50%);
  }
}

@media (max-width: 768px) {
  @keyframes slide-in {
    from {
      transform: translateX(-50%) translateY(100%);
    }
    to {
      transform: translateX(-50%) translateY(0%);
    }
  }
}

@keyframes spinning {
  to {
    transform: rotate(360deg);
  }
}

.slidee {
  animation: slide-in 500ms cubic-bezier(0.32, 0.72, 0, 1);
}
.animate-spinning {
  animation: spinning 0.7s linear infinite;
}
.loader-11 {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #f7941e #f7941e transparent transparent;
  animation: rotation 1s linear infinite;
}
.loader-11:after,
.loader-11:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ef4136 #ef4136;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader-11:before {
  width: 36px;
  height: 36px;
  border-color: #808285 #808285 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

/* .overflowHandle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

@keyframes rotation1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.backgroundColorChange {
  position: relative;
  z-index: 300;
}

.backgroundColorChange::before {
  content: "";
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 6rem;
  position: absolute;
  background-color: #fef2e4;
  z-index: 100;
  transition: 200ms;
  animation: bgfade 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes bgfade {
  0% {
    width: 0%;
    height: 0%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

.dashedBorderCards {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
}
